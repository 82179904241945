export default {
        token(state) {
            return state.token;
        },
        userData(state) {
            return state.userData;
        },
        userCurrentStatus(state) {
            return state.userCurrentStatus;
        },
        userId(state) {
            return state.userData.data.id;
        },
        mopCardData(state) {
            return state.mopCardData;
        },
        userRole(state) {
            return state.userRole
        },
        menuList(state) {
            return state.menuList;
        },
        activeTab(state) {
            //tab.text (на русском)
            return state.activeTab;
        },
        activeTabName(state) {
            //tab.name (na angl)
            return state.menuList.find(tab => tab.active)?.name;
        },
        meetingsData(state) {
            return state.meetingsData;
        },
        employeesData(state) {
            return state.employeesData;
        },
        meetingTableHeaderName(state) {
            return state.meetingTableHeaderName;
        },
        employeesTableHeaderName(state) {
            return state.employeesTableHeaderName;
        },
        mopButtonData(state) {
            return state.mopButtonData;
        },
        isMopBusy(state) {
            return state.isMopBusy;
        },
        selectEmployeesList(state) {
            return state.selectEmployeesList;
        },
        activeMeetingStatus(state) {
            return state.activeMeetingStatus;
        },
        disabledActionStatus(state) {
            return state.disabledActionStatus;
        },
        disabledUserStatus(state) {
            return state.disabledUserStatus;
        },
        meetingMetricsData(state) {
            return state.meetingMetricsData;
        },
        employeesMetricsData(state) {
            return state.employeesMetricsData;
        },
        locationId(state) {
            return state.locationId;
        },
        mopTeamId(state) {
            return state.mopTeamId;
        },
        addUserFormData(state) {
            return state.addUserFormData;
        },
        usersFieldData(state) {
            return state.usersFieldData;
        },
        userDataInAdminTable(state) {
            return state.userDataInAdminTable;
        },
        adminTableHeaders(state) {
            return state.adminTableHeaders;
        },
        addUserFormDataParams(state) {
            return state.addUserFormDataParams;
        },
        propsUserDataInAdminTable(state) {
                return state.propsUserDataInAdminTable
        },
        addTeamFormData(state){
            return state.addTeamFormData;
        },
        adminTableTeamHeaders(state){
            return state.adminTableTeamHeaders;
        },
        teamDataInAdminTable(state){
            return state.teamDataInAdminTable
        },
        propsTeamDataInAdminTable(state) {
                return state.propsTeamDataInAdminTable
        },
        locationDataInAdminTable(state){
                return state.locationDataInAdminTable;
        },
        adminTableLocationHeaders(state){
                return state.adminTableLocationHeaders
        },
        addLocationFormData(state){
                return state.addLocationFormData;
        },
        propsLocationDataInAdminTable(state){
                return state.propsLocationDataInAdminTable;
        },
        addTeamFormDataParams(state){
                return state.addTeamFormDataParams;
        },
        addLocationFormDataParams(state){
                return state.addLocationFormDataParams;
        },
        teamsForLocationFormData(state){
                return state.teamsForLocationFormData;
        },
        addressList(state){
                return state.addressList
        }
}