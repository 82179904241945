<template>
  <router-view/>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "GN-EQ";
  src: url("./assets/fonts-icon/GN-EQ.ttf") format("truetype");
  src: url("./assets/fonts-icon/GN-EQ.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
</style>