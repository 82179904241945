import {httpClient} from "@/httpClient/httpClient";

export default {
    async getPromoList({commit}) {
        try {
            const response = await httpClient.get('/api/files');
            const promoList = response.data.data.reverse();
            commit('setPromoList', promoList);
        } catch (error) {
            console.error('Error fetching promo list:', error);
        }
    },
    async getLocationList({commit}) {
        try {
            const response = await httpClient.get('/api/locations');
            const locationList = response.data.data;
            commit('setLocationList', locationList);
        } catch (error) {
            console.error('Error fetching location list:', error);
        }
    },
    async deletePromo({ commit, dispatch }, promo) {
        try {
            const response = await httpClient.delete(`/api/file/${promo.id}`);

            commit('setShowToast', true);
            commit('setWarningText', 'Ваше промо успешно удалено');

            dispatch('getPromoList');
            setTimeout(() => {
                commit('setShowToast', false);
            }, 2000);
        } catch (error) {
            commit('setShowToast', true);
            commit('setWarningText', error.response.data.message || 'Произошла ошибка');

            setTimeout(() => {
                commit('setShowToast', false);
            }, 5000);
        }
    },
    async deactivatePromo({ commit, dispatch }, promo) {
        try {
            const active = promo.deleted_at === null;
            const params = {
                active: !active
            };

            const response = await httpClient.patch(`/api/file/${promo.id}`, params);
            commit('setShowToast', true);
            commit('setWarningText', `Ваше промо успешно ${promo.deleted_at ? 'активировано' : 'деактивировано'}`);
            dispatch('getPromoList');
            setTimeout(() => {
                commit('setShowToast', false);
            }, 2000);
        } catch (error) {
            commit('setShowToast', true);
            commit('setWarningText', error.response.data.message || 'Произошла ошибка');
            setTimeout(() => {
                commit('setShowToast', false);
            }, 5000);
        }
    },
    async submitForm({ commit, dispatch }, {promo, formData}) {
        await httpClient.patch(`api/file/${promo.id}`, formData).then(resp => {
            commit('setShowToast', true);
            commit('setWarningText', `Ваше промо успешно отредактировано`);
            dispatch('getPromoList');
            setTimeout(() => {
                commit('setShowToast', false);
            }, 2000);
        }).catch(error => {
            commit('setShowToast', true);
            commit('setWarningText', error.response.data.message || "Ошибка при редактировании промо");
            setTimeout(() => {
                commit('setShowToast', false);
            }, 5000);
        })
    },
}