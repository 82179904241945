import axios from 'axios';
const userToken = localStorage.getItem('encoded');

let config = {
    baseURL: process.env['VUE_APP_BACK_URL'],
    headers: {
        "Authorization": `Bearer ${userToken}`,
    },
};
const httpClient = axios.create(config);

const authInterceptor = config => {
    return config;
};

httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            window.location.href= '/';
            this.$store.commit('setToken', '');
            localStorage.clear();
        }
        return Promise.reject(error);
    }
);

export { httpClient };

